import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import {
  Input,
  Badge,
  Button,
  message,
  Popconfirm,
  Modal,
  Table,
  Alert,
} from "antd";
import {
  ShoppingCartOutlined,
  ShoppingOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import "./index.css";
import TextArea from "antd/es/input/TextArea";
import { jwtDecode } from "jwt-decode";
import helper from "./helper";
import { pr } from "./products";
function App() {
  const [cartCount, setCartCount] = useState(0);
  const [products, setProducts] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isPresent, setIspresent] = useState(false);
  const [showModal, setShowModal] = useState({ open: false, type: "cart" });
  const [userAddress, setUserAddresss] = useState({
    location: null,
    pincode: null,
    message: null,
    mobile: null,
  });
  const [orders, setOrders] = useState([]);
  const [u, setU] = useState([]);
  const [address, setAddress] = useState({});

  useEffect(() => {
    const callAsycn = async () => {
      const user = await helper.postRequest("/verify");
      if (user.length) {
        setUserAddresss({
          location: user[0]?.address?.location,
          pincode: user[0]?.address?.pincode,
          mobile: user[0]?.address?.mobile,
        });

        if (user[0].isAdmin) {
          const order = await helper.postRequest("/list/placedOrders");
          setU(order);
        }
        const order = await helper.postRequest("/list/order");
        setOrders(
          order.map((i, idx) => {
            return {
              id: idx + 1,
              ...i,
              total: i.quantity * i.price,
            };
          })
        );
      }
    };
    callAsycn();
    if (!cartCount) reset();
    const token = localStorage.getItem("token");
    const parts = token?.split(".");
    if (
      token &&
      parts.length === 3 &&
      Date.now() < new Date(jwtDecode(token)?.exp || 0) * 1000
    ) {
      setIspresent(true);
    }
  }, [cartCount]);

  const reset = () => {
    setProducts(pr);
  };

  const handleCartSelected = (item) => {
    const itemData = { ...products };
    itemData[item].isSelected = true;
    itemData[item].quantity = 1;
    setProducts(itemData);
    setCartCount(cartCount + 1);
  };

  const handleItemSelected = (item, isIncrement) => {
    const itemData = { ...products };
    const quantity = Math.max(
      isIncrement ? itemData[item].quantity + 1 : itemData[item].quantity - 1,
      0
    );
    itemData[item].quantity = quantity;
    setProducts(itemData);
    if (quantity === 0 && cartCount > 0) {
      itemData[item].isSelected = false;
      setCartCount(cartCount - 1);
    }
  };

  const handleLoginSuccess = async (data) => {
    messageApi.open({
      type: "success",
      content: "Google Login Success",
    });
    localStorage.setItem("token", data.credential);

    const user = await helper.postRequest("/verify");
    if (user?.length) {
      setUserAddresss({
        location: user[0]?.address?.location,
        pincode: user[0]?.address?.pincode,
        mobile: user[0]?.address?.mobile,
      });
      const order = await helper.postRequest("/list/order");
      setOrders(
        order?.map((i, idx) => {
          return {
            id: idx + 1,
            ...i,
            total: i.quantity * i.price,
          };
        })
      );
    }

    setIspresent(true);
  };

  const handleLoginFailure = (data) => {
    messageApi.open({
      type: "error",
      content: "This is a error message",
    });
  };

  const handleLogout = () => {
    setIspresent(false);
    localStorage.clear();
    setCartCount(0);
    setOrders([]);
    window.location.reload();
  };

  const handleSearch = ({ target: { value } }) => {
    if (!value.length) {
      reset();
      return;
    }

    const items = { ...products };
    const filteredData = Object.keys(items)
      .filter((i) => i.toLowerCase().includes(value.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = products[key];
        return obj;
      }, {});
    setProducts(filteredData);
  };

  const getData = () => {
    return Object.keys(products)
      .filter((i) => products[i].quantity)
      .map((i) => {
        return {
          name: products[i].name,
          quantity: products[i].quantity,
          price: products[i].price,
          total: products[i].price * products[i].quantity,
          status: "PLACED",
        };
      });
  };

  const handleCancel = async (orderId) => {
    await helper.postRequest("/update/order", {
      orderId,
      status: "c",
    });
    const order = await helper.postRequest("/list/order");
    setOrders(
      order.map((i, idx) => {
        return {
          id: idx + 1,
          ...i,
          total: i.quantity * i.price,
        };
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (item) => item.toLocaleString("en-IN"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (item) => item.toLocaleString("en-IN"),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (item) => item.toLocaleString("en-IN"),
    },
  ];

  const handleDispatch = async (id) => {
    const tp = await helper.postRequest("/update/id", {
      userId: id,
      status: "DISPATCHED",
    });
    setU(tp);
  };

  const handleCanel = async (id) => {
    const tp = await helper.postRequest("/update/id", {
      userId: id,
      status: "CANCELLED",
    });
    setU(tp);
  };

  const handleView = async (id, address) => {
    setAddress(address);
    const tp = await helper.postRequest("/list/id", { userId: id });
    const data = tp.map((i) => {
      return {
        ...i,
        total: i.price * i.quantity,
      };
    });
    setOrders(data);
    // setOrders(data);
    const total = data.reduce((a, b) => a + b.total, 0);
    const t = { ...address };
    t.total = total;
    setAddress(t);
    setShowModal({ type: "order", open: true });
  };

  const columns1 = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (item) => item?.location,
    },
    {
      title: "Phone",
      dataIndex: "address",
      key: "address",
      render: (item) => item?.mobile,
    },
    {
      title: "Pincode",
      dataIndex: "address",
      key: "address",
      render: (item) => item?.pincode,
    },
    {
      title: "",
      dataIndex: "-",
      key: "-",
      render: (_item, data) => (
        <Button onClick={() => handleView(data?._id, data?.address)}>
          View
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "-",
      key: "-",
      render: (_item, data) => (
        <Popconfirm
          placement="bottom"
          title={"Are sure you want to Dispatch?"}
          okText="Yes"
          cancelText="Cancel"
          onConfirm={() => handleDispatch(data?._id)}
        >
          <Button>Dispatch</Button>
        </Popconfirm>
      ),
    },
    {
      title: "",
      dataIndex: "-",
      key: "-",
      render: (_item, data) => (
        <Popconfirm
          placement="bottom"
          title={"Are sure you want to cancel?"}
          okText="Yes"
          cancelText="Cancel"
          onConfirm={() => handleCanel(data?._id)}
        >
          <Button>Cancel</Button>
        </Popconfirm>
      ),
    },
  ];

  const handleLocationUpdate = (type, { target: { value } }) => {
    const address = { ...userAddress };
    address[type] = type === "location" ? value : value.replace(/\D/g, "");
    if (
      (type === "mobile" && value.length > 10) ||
      (type === "pincode" && value.length > 6)
    ) {
      address[type] = userAddress[type];
    }

    if (
      type === "pincode" &&
      !(+value >= 560001 && +value < 560100) &&
      value.length === 6
    ) {
      address["message"] = "Sorry we are not deliverying here...!";
    } else if (type === "pincode") {
      address["message"] = null;
    }
    setUserAddresss(address);
  };

  const handleCheckout = async () => {
    if (
      !userAddress.location?.trim()?.length ||
      !userAddress.pincode?.trim()?.length ||
      !userAddress.mobile?.trim()?.length
    ) {
      messageApi.open({
        type: "error",
        content: "Please enter the location details",
      });
      return;
    }
    setCartCount(0);
    setShowModal({ ...showModal, open: false });
    await helper.postRequest("/create/order", {
      order: getData(),
      address: {
        location: userAddress.location,
        mobile: userAddress.mobile,
        pincode: userAddress.pincode,
      },
    });
    const order = await helper.postRequest("/list/order");
    setOrders(
      order.map((i, idx) => {
        return {
          id: idx + 1,
          ...i,
          total: i.quantity * i.price,
        };
      })
    );
    messageApi.open({
      type: "success",
      content: "Order placed successfully",
    });
  };

  return (
    <GoogleOAuthProvider clientId="1016068504211-q11s2673vl2508otrea4j67s943f1lma.apps.googleusercontent.com">
      {contextHolder}
      <div className="nav">
        <div className="logo">
          <img
            className="logo-image"
            src="https://storage.googleapis.com/organicveggie/assets/logo.png"
            alt="logo"
          />
        </div>
        <div className="search"></div>
        <div className="cart">
          <div className="cart-icon">
            {!isPresent && (
              <GoogleLogin
                width={10}
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            )}
            {isPresent && (
              <div className="detail-container">
                <Popconfirm
                  placement="bottom"
                  title={"Are sure you want to Logout?"}
                  okText="Yes"
                  cancelText="Cancel"
                  onConfirm={handleLogout}
                >
                  <LoginOutlined className="logout" />
                </Popconfirm>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="products">
        <Table
          className="productTable"
          dataSource={u}
          columns={columns1}
          pagination={null}
        />
      </div>
      <footer>
        © 2024. All rights reserved.&nbsp; Contact Us | Terms & Conditions |
        Privacy Policy | Return Policy
      </footer>
      <Modal
        open={showModal.open}
        onCancel={() => setShowModal({ ...showModal, open: false })}
        footer={null}
        width={"50%"}
      >
        {showModal.type === "cart" && (
          <>
            <Table
              className="productTable"
              dataSource={getData()}
              columns={columns}
              pagination={{ pageSize: 4 }}
            />
            <h3 className="notFound">
              Total: ₹
              {getData()
                .reduce((a, b) => a + b.total, 0)
                .toLocaleString("en-IN")}
            </h3>
            {!isPresent && (
              <GoogleLogin
                width={10}
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            )}
            {isPresent && (
              <Popconfirm
                width={500}
                placement="right"
                title={"Please confim your Address"}
                description={() => {
                  return (
                    <>
                      <TextArea
                        value={userAddress.location}
                        placeholder="Please enter your address"
                        onChange={(value) => {
                          handleLocationUpdate("location", value);
                        }}
                      />
                      <Input
                        className="pincode"
                        type="tel"
                        placeholder="Please Enter Phone"
                        value={userAddress.mobile}
                        onChange={(value) => {
                          handleLocationUpdate("mobile", value);
                        }}
                      />

                      <Input
                        className="pincode"
                        placeholder="Please Enter Pincode"
                        value={userAddress.pincode}
                        onChange={(value) => {
                          handleLocationUpdate("pincode", value);
                        }}
                      />

                      {userAddress.message && (
                        <Alert message={userAddress.message} type="error" />
                      )}
                    </>
                  );
                }}
                okText="Place Order"
                cancelText="Cancel"
                onConfirm={() => handleCheckout()}
              >
                <Button className="button">Checkout</Button>
              </Popconfirm>
            )}
          </>
        )}
        {showModal.type === "order" && (
          <>
            <h1>Organic Veggie</h1>
            <div>Address: {address?.location}</div>
            <div>Pincode: {address?.pincode}</div>
            <div>Mobile: {address?.mobile}</div>
            <Table
              className="productTable"
              width={"200px"}
              dataSource={orders}
              columns={columns}
              pagination={{ pageSize: 20 }}
            />
            <div>Total: {address?.total || "-"}</div>
          </>
        )}
      </Modal>
    </GoogleOAuthProvider>
  );
}

export default App;
