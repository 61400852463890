import axios from "axios";

export const postRequest = async (endpoint, body = {}) => {
  try {
    // Sending POST request using axios
    const response = await axios.post("http://localhost:4000" + endpoint, {
      ...body,
      token: localStorage.getItem("token"),
    });
    return response.data;
  } catch (error) {
    return;
  }
};
// eslint-disable-next-line
export default { postRequest };
